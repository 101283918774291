import React, { useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { LocaleContext } from "context/locale-context";
import LocalizedLink from "components/localizedLink";
import useTranslations from "hooks/useTranslations";
import { isNil } from "ramda";
import { ThemeContext } from "context/theme-context";

export default function Map({ incidents, viewport, cssClass = "leaflet-map" }) {
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);
  const mapState = {
    attribution: tr(
      "For security reasons, the location coordinates used for maps in this database and report are approximated and intentionally, slightly inaccurate."
    ),
    tile: "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png",
  };

  if (isNil(viewport)) return <div />;
  return (
    <MapContainer
      center={[viewport?.lat, viewport?.lng]}
      zoom={viewport?.zoom}
      scrollWheelZoom={false}
      className={cssClass}
    >
      <TileLayer attribution={mapState.attribution} url={mapState.tile} />
      {incidents?.map((node) => {
        if (!isNaN(Number(node.latitude)) && !isNaN(Number(node.longitude))) {
          return (
            <Marker
              key={node.id}
              position={{
                lat: Number(node.latitude),
                lng: Number(node.longitude),
              }}
              riseOnHover={true}
              opacity="0"
            >
              <Popup>
                <LocalizedLink to={`incident?id=${node.id}`}>
                  {node.title}
                </LocalizedLink>
              </Popup>
              <Circle
                center={{
                  lat: Number(node.latitude),
                  lng: Number(node.longitude),
                }}
                fill={true}
                stroke={false}
                opacity={0}
                fillColor={colors.primary}
                radius={viewport?.radius || 5000}
                fillOpacity={0.7}
              />
            </Marker>
          );
        }
      })}
    </MapContainer>
  );
}
