import React, { useContext } from "react";
import PropTypes from "prop-types";
import IncidentDetails from "components/database/incident-details";
import UnitLayout from "components/unit-layout";
import { LocaleContext } from "context/locale-context";
import useDataApi from "hooks/useDataApi";
import { ThemeContext } from "context/theme-context";
// This will be used in next few weeks to make a generalised observation Details for all sites, override it for now
function IncidentWrapper({ id }) {
  const locale = useContext(LocaleContext);
  const { colors, apiAuthHeader, apiUrl } = useContext(ThemeContext);
  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${apiUrl}/yemen/${locale}/incidents/${id}`,
    [],
    apiAuthHeader
  );

  // get observation details and pass on to the details component
  return (
    <>
      <UnitLayout location={{}} isModal={false}>
        <IncidentDetails data={data} locale={locale} loading={isLoading} />
      </UnitLayout>
    </>
  );
  // return <>Unit Details</>;
}

IncidentWrapper.propTypes = {
  id: PropTypes.object,
  locale: PropTypes.string,
};

export default IncidentWrapper;
